import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | FAQ - Shenzhen Starnoon Technology Co., LTD
			</title>
			<meta name={"description"} content={"At Shenzhen Starnoon Technology Co., LTD, we understand that planning an event can come with many questions. Here are answers to some common queries to help guide you through our event planning process.\n"} />
			<meta property={"og:title"} content={"FAQ | FAQ - Shenzhen Starnoon Technology Co., LTD"} />
			<meta property={"og:description"} content={"At Shenzhen Starnoon Technology Co., LTD, we understand that planning an event can come with many questions. Here are answers to some common queries to help guide you through our event planning process.\n"} />
			<meta property={"og:image"} content={"https://tlastpainter.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tlastpainter.com/img/icon-7.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://tlastpainter.com/img/icon-7.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://tlastpainter.com/img/icon-7.jpg"} />
		</Helmet>
		<Components.Eader />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Frequently asked questions
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				At Shenzhen Starnoon Technology Co., LTD, we understand that planning an event can come with many questions. Here are answers to some common queries to help guide you through our event planning process.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						What types of events do you specialize in?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						We specialize in a wide range of events, including weddings, corporate events, private parties, and special celebrations.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Can you work with my specific budget?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Absolutely! We tailor our services to fit various budget ranges and will work with you to maximize the impact of your budget.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						How far in advance should I book your services?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						We recommend booking as early as possible, especially for larger events, to ensure availability and ample planning time.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Do you provide event design and theme development?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Yes, we offer complete event design services, including theme development and decor conceptualization.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Can Shenzhen Starnoon Technology Co., LTD handle vendor coordination?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						We handle all aspects of vendor coordination, from selection and contract negotiation to day-of event management.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Do you offer catering services?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						While we do not provide catering directly, we work closely with excellent catering partners to suit your culinary needs.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						What is your cancellation policy?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Our cancellation policy varies depending on the specifics of your event and will be detailed in our service agreement.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Do you offer destination event planning?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Yes, we can plan and manage destination events and will provide logistics and travel support as needed.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					For any additional questions or to begin planning your event with Shenzhen Starnoon Technology Co., LTD, please feel free to contact us.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contacts
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://tlastpainter.com/img/9.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="300px"
					max-width="480px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});